import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Inscription {
  classe: string;
  nom_eleve: string;
  prenom_eleve: string;
  date_naissance: string;
  lieu_naissance: string;
  sexe: string;
  adresse_eleve: string;
  situation_familiale: string;
  avec: string;
  responsable_legal: string;
  nom_parent: string;
  prenom_parent: string;
  adresse_parent: string;
  profession: string;
  nom_societe: string;
  phone: string;
  status: string;
}

export interface InscriptionStatus {
   _id?:string;
    status: string;
  }

export const inscriptionsSlice = createApi({
  reducerPath: "Inscription",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://backend-ecole-primaire.onrender.com/api/inscriptions/",
  }),
  tagTypes: ["Inscription", "InscriptionStatus"],
  endpoints(builder) {
    return {
      getAllInscriptions: builder.query<Inscription[], number | void>({
        query() {
          return "/getInscriptions";
        },
        providesTags: ["Inscription"],
      }),
      addNewInscription: builder.mutation<void, Inscription>({
        query(payload) {
          return {
            url: "/createInscription",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["Inscription"],
      }),
      updateInscriptionStataus: builder.mutation<void, InscriptionStatus>({
        query(payload) {
          return {
            url: "/updateInscriptionStatus",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: ["Inscription", "InscriptionStatus"],
      }),
      removeInscription: builder.mutation<void, number>({
        query: (idNote) => ({
          url: `deleteInscription/${idNote}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Inscription"],
      }),
    };
  },
});

export const {
  useAddNewInscriptionMutation,
  useGetAllInscriptionsQuery,
  useRemoveInscriptionMutation,
  useUpdateInscriptionStatausMutation
} = inscriptionsSlice;
