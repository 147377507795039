import React, { useState } from "react";
import {
  Container,
  Row,
  Card,
  Col,
  Modal,
  Form,
  Button,
  Offcanvas,
  Image,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import Breadcrumb from "Common/BreadCrumb";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Flatpickr from "react-flatpickr";
import { useFetchClassesQuery } from "features/classes/classeSlice";
import Select from "react-select";
import { useAddGallerieMutation } from "features/galleries/gallerieSlice";
import Dropzone from "react-dropzone";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import { Pagination } from "swiper/modules";
import { Autoplay } from "swiper/modules";
import {
  useGetAllInscriptionsQuery,
  useRemoveInscriptionMutation,
  useUpdateInscriptionStatausMutation,
} from "features/inscriptions/inscriptionSlice";

interface InscriptionRow {
  _id: string;
  nom_eleve: string;
  prenom_eleve: string;
  date_naissance: string;
  lieu_naissance: string;
  classe: string;
  status: string;
  // Add other properties as needed
}

const Inscriptions = () => {
  const { data = [] } = useGetAllInscriptionsQuery();

  const { data: AllClasses = [] } = useFetchClassesQuery();

  const [deleteInscription] = useRemoveInscriptionMutation();

  const [showGallerie, setShowGallerie] = useState<boolean>(false);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const handleDateChange = (selectedDates: Date[]) => {
    setSelectedDate(selectedDates[0]);
  };

  const notifySuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "La gallerie a été crée avec succès",
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const notifyError = (err: any) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: `Sothing Wrong, ${err}`,
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const AlertDelete = async (_id: any) => {
    swalWithBootstrapButtons
      .fire({
        title: "Etes-vous sûr?",
        text: "Vous ne pouvez pas revenir en arrière?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprime-le !",
        cancelButtonText: "Non, annuler !",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteInscription(_id);
          swalWithBootstrapButtons.fire(
            "Supprimé !",
            "L'inscription est supprimée.",
            "success"
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire(
            "Annulé",
            "L'inscription est en sécurité :)",
            "info"
          );
        }
      });
  };

  const [modal_AddGallerie, setmodal_AddGallerie] = useState<boolean>(false);
  function tog_AddGallerie() {
    setmodal_AddGallerie(!modal_AddGallerie);
  }

  const [modal_UpdateGallerie, setmodal_UpdateGallerie] =
    useState<boolean>(false);
  function tog_UpdateGallerie() {
    setmodal_UpdateGallerie(!modal_UpdateGallerie);
  }

  const optionColumnsTable = AllClasses.map((classe: any) => ({
    value: classe?._id!,
    label: classe?.nom_classe!,
  }));

  const [selectedColumnValues, setSelectedColumnValues] = useState<any[]>([]);

  const handleSelectValueColumnChange = (selectedOption: any) => {
    const values = selectedOption.map((option: any) => option.value);
    setSelectedColumnValues(values);
  };

  const [createGallerie] = useAddGallerieMutation();
  const [updateStatus] = useUpdateInscriptionStatausMutation();
  const initialGallerie = {
    classes: [""],
    titre: "",
    desc: "",
    creation_date: "",
    fichier_base64_string: [],
    fichier_extension: [],
    fichiers: [],
  };

  const [gallerie, setGallerie] = useState(initialGallerie);

  const {
    classes,
    titre,
    desc,
    creation_date,
    fichier_base64_string,
    fichier_extension,
    fichiers,
  } = gallerie;

  const onChangeGallerie = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setGallerie((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleDeleteFile = (indexToRemove: number) => {
    setGallerie((prevData) => {
      const newGallery = prevData.fichiers?.filter(
        (_, index) => index !== indexToRemove
      );
      const newGalleryBase64Strings = prevData.fichier_base64_string?.filter(
        (_, index) => index !== indexToRemove
      );
      const newGalleryExtension = prevData.fichier_extension?.filter(
        (_, index) => index !== indexToRemove
      );

      return {
        ...prevData,
        fichiers: newGallery,
        fichier_base64_string: newGalleryBase64Strings,
        fichier_extension: newGalleryExtension,
      };
    });
  };

  const onSubmitGallerie = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      gallerie["creation_date"] = selectedDate?.toDateString()!;
      gallerie["classes"] = selectedColumnValues;
      createGallerie(gallerie)
        .then(() => notifySuccess())
        .then(() => setGallerie(initialGallerie));
    } catch (error) {
      notifyError(error);
    }
  };

  const [selectedRow, setSelectedRow] = useState<InscriptionRow | null>(null);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (row: any) => {
    setSelectedRow(row);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
  };

  const handleUpdateStatus = (status: any) => {
    if (selectedRow) {
      updateStatus({ _id: selectedRow._id, status }).then(() => {
        handleCloseModal(); // Close the modal after the update
      });
    }
  };

  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Classe</span>,
      selector: (row: any) => row.classe,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Nom Elève</span>,
      selector: (row: any) => (
        <span>
          {row.nom_eleve} {row.prenom_eleve}
        </span>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-13">
          Date et Lieu de Naissance
        </span>
      ),
      selector: (row: any) => (
        <span>
          {row.date_naissance} à {row.lieu_naissance}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Nom Parent</span>,
      selector: (row: any) => (
        <span>
          {row.nom_parent} {row.prenom_parent}
        </span>
      ),
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">N° Téléphone</span>,
      selector: (row: any) => row.phone,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Adresse Parent</span>,
      selector: (row: any) => row.adresse_parent,
      sortable: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Etat</span>,
      selector: (cell: any) => {
        switch (cell.status) {
          case "":
            return <span className="badge bg-warning"> En Attente </span>;
          case "Accepté":
            return <span className="badge bg-success"> {cell.status} </span>;
          case "Refusé":
            return <span className="badge bg-danger"> {cell.status} </span>;
          default:
            return <span className="badge bg-warning"> En Attente </span>;
        }
      },
      sortable: true,
      width: "100px",
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      sortable: true,
      cell: (row: any) => {
        return (
          <ul className="hstack gap-2 list-unstyled mb-0">
            {/* <li>
              <Link
                to="#"
                className="badge badge-soft-info edit-item-btn"
                onClick={() => setShowGallerie(!showGallerie)}
                state={row}
              >
                <i
                  className="ri-eye-line"
                  style={{
                    transition: "transform 0.3s ease-in-out",
                    cursor: "pointer",
                    fontSize: "1.2em",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.3)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                ></i>
              </Link>
            </li> */}
            <li>
              <Link
                to="#"
                className="badge badge-soft-success edit-item-btn"
                onClick={() => handleOpenModal(row)}
              >
                <i
                  className="ri-check-double-line"
                  style={{
                    transition: "transform 0.3s ease-in-out",
                    cursor: "pointer",
                    fontSize: "1.2em",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.3)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                ></i>
              </Link>
            </li>
            <li>
              <Link to="#" className="badge badge-soft-danger remove-item-btn">
                <i
                  className="ri-delete-bin-2-line"
                  style={{
                    transition: "transform 0.3s ease-in-out",
                    cursor: "pointer",
                    fontSize: "1.2em",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.3)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                  onClick={() => AlertDelete(row._id)}
                ></i>
              </Link>
            </li>
          </ul>
        );
      },
    },
  ];

  const gallerieLocation = useLocation();

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const getFilteredEvenements = () => {
    let filteredEvenement = data;

    if (searchTerm) {
      filteredEvenement = filteredEvenement.filter((evenement: any) => {
        const titleMatch = evenement?.titre
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const typeMatch = evenement?.type
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const dateMatch = evenement?.creation_date
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const classMatch = evenement?.classes?.some((classe: any) =>
          classe?.nom_classe?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return titleMatch || dateMatch || classMatch || typeMatch;
      });
    }

    return filteredEvenement;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Inscription" pageTitle="Tableau de bord" />
          <Col lg={12}>
            <Card id="shipmentsList">
              <Card.Header className="border-bottom-dashed">
                <Row className="g-3">
                  <Col lg={3}>
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Rechercher ..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </Col>
                  <Col lg={6}></Col>
                </Row>
              </Card.Header>
              <Card.Body>
                <DataTable columns={columns} data={data} pagination />
              </Card.Body>
            </Card>
          </Col>
          <Modal
            className="fade"
            id="createModal"
            show={modal_AddGallerie}
            onHide={() => {
              tog_AddGallerie();
            }}
            centered
          >
            <Modal.Header closeButton>
              <h1 className="modal-title fs-5" id="createModalLabel">
                Ajouter Gallerie
              </h1>
            </Modal.Header>
            <Modal.Body>
              <Form className="create-form" onSubmit={onSubmitGallerie}>
                <Row className="mb-4">
                  <Col lg={3}>
                    <Form.Label htmlFor="titre">Titre</Form.Label>
                  </Col>
                  <Col lg={8}>
                    <Form.Control
                      type="text"
                      id="titre"
                      name="titre"
                      onChange={onChangeGallerie}
                      value={gallerie.titre}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={3}>
                    <Form.Label htmlFor="classe">Classe(s)</Form.Label>
                  </Col>
                  <Col lg={8}>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      options={optionColumnsTable}
                      onChange={handleSelectValueColumnChange}
                      placeholder="Choisir..."
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={3}>
                    <Form.Label htmlFor="desc">Description</Form.Label>
                  </Col>
                  <Col lg={8}>
                    <textarea
                      className="form-control"
                      id="desc"
                      name="desc"
                      value={gallerie.desc}
                      onChange={onChangeGallerie}
                      rows={3}
                    ></textarea>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={3}>
                    <Form.Label htmlFor="date">Date Création</Form.Label>
                  </Col>
                  <Col lg={8}>
                    <Flatpickr
                      className="form-control flatpickr-input"
                      placeholder="Date Création"
                      onChange={handleDateChange}
                      options={{
                        dateFormat: "d M, Y",
                      }}
                      id="date"
                      name="date"
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col lg={3}>
                    <Form.Label htmlFor="fichier_base64_string">
                      Fichier
                    </Form.Label>
                  </Col>
                  <Col lg={6}>
                    <Dropzone>
                      {({ getRootProps, getInputProps }) => (
                        <div
                          className="dropzone dz-clickable text-center"
                          {...getRootProps()}
                        >
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h5>
                              Déposez des photos ici ou cliquez pour
                              télécharger.
                            </h5>
                          </div>
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                  </Col>
                  <Col lg={3}>
                    <div className="mt-3">
                      {gallerie.fichiers?.map((image, index) => (
                        <div key={index} className="image-preview">
                          <img
                            src={image}
                            alt={`Image ${index + 1}`}
                            className="img-thumbnail"
                          />
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteFile(index)}
                          >
                            Supprimer
                          </Button>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="hstack gap-2 justify-content-end">
                    <Button
                      variant="light"
                      onClick={() => {
                        tog_AddGallerie();
                        setGallerie(initialGallerie);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      onClick={() => {
                        tog_AddGallerie();
                      }}
                      type="submit"
                      variant="success"
                      id="addNew"
                    >
                      Ajouter
                    </Button>
                  </div>
                </Row>
              </Form>
            </Modal.Body>
          </Modal>
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>Modifier L'état</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Display data of the specific row */}
              {selectedRow && (
                <>
                  <p>
                    Nom Elève: {selectedRow.nom_eleve}{" "}
                    {selectedRow.prenom_eleve}
                  </p>
                  <p>Date de Naissance: {selectedRow.date_naissance}</p>
                  <p>Classe: {selectedRow.classe}</p>
                  {/* Add more row data as needed */}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="success"
                onClick={() => handleUpdateStatus("Accepté")}
              >
                Accepter
              </Button>
              <Button
                variant="danger"
                onClick={() => handleUpdateStatus("Refusé")}
              >
                Refuser
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
        <Offcanvas
          show={showGallerie}
          onHide={() => setShowGallerie(!showGallerie)}
          placement="end"
          style={{ width: "40%" }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Détails du Gallerie</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row className="mb-3">
              <Col lg={3}>
                <span className="fw-medium">Titre</span>
              </Col>
              <Col lg={9}>
                <i>{gallerieLocation?.state?.titre!}</i>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <span className="fw-medium">Description</span>
              </Col>
              <Col lg={9}>
                <i>{gallerieLocation?.state?.desc!}</i>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <span className="fw-medium">Date création</span>
              </Col>
              <Col lg={9}>
                <i>{gallerieLocation?.state?.creation_date!}</i>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={3}>
                <span className="fw-medium">Classe(s)</span>
              </Col>
              <Col lg={9}>
                <i>
                  {gallerieLocation?.state?.classes
                    ?.map((c: any) => c.nom_classe)
                    .join(" / ")}
                </i>
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <span className="fw-medium">Fichiers</span>
              </Col>
              <Col lg={9}>
                <Swiper
                  direction={"vertical"}
                  pagination={{ clickable: true }}
                  modules={[Pagination, Autoplay]}
                  loop={true}
                  autoplay={{ delay: 2500, disableOnInteraction: false }}
                  className="mySwiper swiper vertical-swiper rounded"
                  style={{ height: "324px" }}
                >
                  {gallerieLocation?.state?.fichiers!.map((image: any) => (
                    <div className="swiper-wrapper">
                      <SwiperSlide>
                        <Image
                          // src={`https://backend-ecole-primaire.onrender.com/gallerieFiles/${image}`}
                          src={`https://backend-ecole-primaire.onrender.com/gallerieFiles/${image}`}
                          alt=""
                          className="img-fluid"
                        />
                      </SwiperSlide>
                    </div>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  );
};
export default Inscriptions;
